<template>
  <div class="user-activation">
    <section class="user-activation__expired section">
      <div class="section-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10 is-5-desktop">
              <transition name="fade">
                <div
                  v-if="!passwordReset"
                  class="form"
                >
                  <h5 class="title is-5">
                    {{ $t('pages.user_password_reset.input_your_new_password') }}
                  </h5>
                  <form @submit.prevent="resetPassword">
                    <form-field
                      :validator="$v.form.password"
                      :label="$t('forms.password') "
                    >
                      <input
                        v-model="form.password"
                        :placeholder="$t('forms.password')"
                        class="input"
                        type="password"
                        name="password"
                        @input="$v.form.password.$touch()"
                      >
                    </form-field>
                    <form-field
                      :validator="$v.form.password_confirmation"
                      :label="$t('forms.password_confirmation') "
                    >
                      <input
                        v-model="form.password_confirmation"
                        :placeholder="$t('forms.password_confirmation')"
                        class="input"
                        type="password"
                        name="password_confirmation"
                        @input="$v.form.password_confirmation.$touch()"
                      >
                    </form-field>
                    <div class="field has-text-right">
                      <button
                        :class="{'is-loading': isLoading}"
                        class="button is-primary"
                      >
                        {{ $t('forms.send') }}
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  v-if="passwordReset"
                  class="notification is-success"
                >
                  {{ $t('success.successfully_reset_password') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  props: {
    usersEncodedId: { type: String, default: '' },
    activationCode: { type: String, default: '' }
  },
  validations: {
    form: {
      password: {
        'password.required': required,
        'password.min': minLength(6)
      },
      password_confirmation: {
        'password.required': required,
        'password.min': minLength(6),
        same_as: sameAs('password')
      }
    }
  },
  data () {
    return {
      passwordReset: false,
      isLoading: false,
      form: {
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    resetPassword () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.isLoading = true
      this.$api.post('/passwords/reset', {
        password_confirmation: this.form.password_confirmation,
        password: this.form.password,
        code: this.activationCode,
        user_id: this.usersEncodedId
      })
        .then(response => {
          this.isLoading = false
          this.passwordReset = true
          // this.$notify.success(this.$t('success.successfully_sent_new_email_with_token'))
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error)
        })
    }
  }
}
</script>
